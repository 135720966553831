
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';
import Editable from '@/components/Editable.vue';

export default defineComponent({
    components: { Editable, FormsReportTypes },
    mixins: [Base],
    data(): any {
        return {
            rendererUrl: process.env.VUE_APP_RENDERER_URL,
            items: {
                data: [],
                meta: {},
            },
            params: {
                filter: '',
                forms_report_type: undefined,
                page: 1,
                sortBy: 'created_at',
                orderBy: 'desc',
            },
        };
    },
    mounted(): void {
        this.getItems();
    },
    methods: {
        getItems(page: number = 1): void {
            this.params.page = page;
            this.get('infringement-categories', {
                params: {
                    ...this.params,
                    forms_report_type_id: this.params.forms_report_type ? this.params.forms_report_type.id : null,
                },
            })
                .then(({ data }: any) => {
                    this.items = data;
                });
        },
    },
});
